

























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component
export default class InsInput extends Vue {
    @Prop({ default: 'label' }) private label!:string;
    @Prop({ default: false }) private needLabel!:boolean;
    @Prop({ default: '' }) private value!:string;
    @Prop({ default: true }) private border!:boolean;
    @Prop({ default: 'text' }) private type!:string;
    @Prop({ default: true }) private needValidate!:boolean;
    @Prop({ default: '' }) private placeholder!:string;
    @Prop({ default: '' }) private width!: string;
    @Prop({ default: 'block' }) private display!: string;
    @Prop({ default: '' }) private rule!:any;
    @Prop({ default: true }) private must!: boolean;
    @Prop({ default: '120px' }) private labelWidth!:string;
    @Prop({ default: false }) private disabled!:boolean;
    private tm = '';
    private Value: string = '';
    private error: boolean = true;
    private none: boolean = false;
    private static defaultRule:any = { 'textarea': '', 'text': '', 'email': /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w+$/, 'url': '', 'number': /^\d+$/, 'range': '', 'color': '' };
    created () {
      this.Value = this.value;
      this.tm = 'Input.' + this.type;
    }
    @Watch('Value')
    onValueChange () {
      if (this.Value !== '') this.none = false;
      this.Shake(() => {
        if (InsInput.defaultRule[this.type] instanceof RegExp) {
          this.error = (InsInput.defaultRule[this.type] as RegExp).test(this.Value) || this.Value === '';
          // if (this.error === false) this.$emit('error');
        };
        this.$emit('input', this.Value);
      });
    }
    @Watch('value')
    onvalueChange () {
      this.Value = this.value;
    }
    blur () {
      if (this.Value === '' && this.must) this.none = true;
      else this.none = false;
    }
    validate () {
      if (!this.needValidate || !this.must) return true;
      if (InsInput.defaultRule[this.type] instanceof RegExp) {
        this.error = (InsInput.defaultRule[this.type] as RegExp).test(this.Value) || this.Value === '';
        if (this.error === false) return false;
      };
      if (!this.rule) {
      } else {
        if (this.rule instanceof RegExp) this.error = (this.rule as RegExp).test(this.Value) || this.Value === '';
        else if (typeof this.rule === 'string') this.error = (this.rule === this.Value);
        if (this.error === false) return false;
      }
      if (this.Value === '') { this.none = true; return false; }
      return true;
    }
    reset () {
      this.Value = '';
    }
}
